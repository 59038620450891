import stylesheetUrl from './styles/index.css?url';

import type { LoaderFunctionArgs } from '@remix-run/server-runtime';
import { json } from '@remix-run/node';
import { withSentry } from '@sentry/remix';

import {
  Links,
  Outlet,
  Scripts,
  ScrollRestoration,
  Meta,
  useRouteLoaderData,
} from '@remix-run/react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import classNames from 'clsx';

import Head from '~/core/ui/Head';
import { parseThemeCookie } from '~/lib/server/cookies/theme.cookie';
import AppRouteLoadingIndicator from '~/components/AppRouteLoadingIndicator';
import RootErrorBoundary from '~/components/RootErrorBoundary';
import i18nNext from './i18n/i18n.server';
import configuration from '~/configuration';

const queryClient = new QueryClient();

export const links = () => {
  const links = [
    { rel: 'stylesheet', href: stylesheetUrl },
    { rel: "preconnect",  href: "https://fonts.googleapis.com" },
    { rel: "preconnect",  href: "https://fonts.gstatic.com", crossOrigin: 'anonymous' },
    { rel: "stylesheet", href: "https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap" }
  ];

  return links;
};

export const meta = () => {
  return [
    { title: configuration.site.siteName },
    {
      name: "description",
      content: configuration.site.description
    },
    {
      property: "og:title",
      content: configuration.site.name
    },
    {
      property: "og:description",
      content: configuration.site.description
    },
  ];
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const theme = await parseThemeCookie(request);
  const locale = await i18nNext.getLocale(request);
  const env = getBrowserEnvironment();

  return json({
    locale,
    theme,
    ENV: env,
  });
};

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useRouteLoaderData<typeof loader>("root");

  const className = classNames({
    dark: data?.theme === 'dark',
  });

  return (
    <html lang={data?.locale} className={className} data-app-version={data?.ENV.APP_VERSION}>
      <head>
        <Meta />
        <Links />
        <Head />

        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data?.ENV)}`,
          }}
        />
        {configuration.production && <script defer data-domain="app.dealday.io" src="https://plausible.io/js/script.outbound-links.js"></script>}
      </head>
      <body className="h-full">
        <QueryClientProvider client={queryClient}>
          {children}
        </QueryClientProvider>

        <AppRouteLoadingIndicator />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

function App() {
  return <Outlet />;
}

export const ErrorBoundary = RootErrorBoundary;

function getBrowserEnvironment() {
  const env = process.env;

  return {
    IS_CI: env.IS_CI,
    SITE_URL: env.SITE_URL,
    DEFAULT_LOCALE: env.DEFAULT_LOCALE,
    NODE_ENV: env.NODE_ENV,
    SENTRY_DSN: env.SENTRY_DSN,
    SUPABASE_URL: env.SUPABASE_URL,
    SUPABASE_ANON_KEY: env.SUPABASE_ANON_KEY,
    ENVIRONMENT: env.ENVIRONMENT,
    STRIPE_PUBLISHABLE_KEY: env.STRIPE_PUBLISHABLE_KEY,
    ENABLE_ACCOUNT_DELETION: env.ENABLE_ACCOUNT_DELETION,
    ENABLE_ORGANIZATION_DELETION: env.ENABLE_ORGANIZATION_DELETION,
    APP_VERSION: env.APP_VERSION,
    NANGO_PUBLIC_KEY: env.NANGO_PUBLIC_KEY
  };
}

export default withSentry(App);