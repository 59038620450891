import { useNavigation } from '@remix-run/react';
import { createRef, useEffect, useRef, lazy } from 'react';

import type { LoadingBarRef } from 'react-top-loading-bar';
import isBrowser from '~/core/generic/is-browser';
import ClientOnly from '~/core/ui/ClientOnly';

const TopLoadingBar = lazy(() => import('react-top-loading-bar'));

function AppRouteLoadingIndicator() {
  return (
    <ClientOnly>
      <LoadingBar />
    </ClientOnly>
  );
}

function LoadingBar() {
  const ref = createRef<LoadingBarRef>();
  const runningRef = useRef(false);
  const navigation = useNavigation();
  const state = navigation.state;

  useEffect(() => {
    const isIdle = state === 'idle';
    const isRouteLoading = state === 'loading' || state === 'submitting';

    if (isRouteLoading) {
      ref.current?.continuousStart();
    }

    if (isIdle) {
      ref.current?.complete();
      runningRef.current = false;
    }
  }, [ref, state]);

  if (!isBrowser()) {
    return null;
  }

  return (
    <TopLoadingBar
      height={4}
      waitingTime={200}
      shadow
      className={'bg-primary'}
      color={''}
      ref={ref}
    />
  );
}

export default AppRouteLoadingIndicator;
